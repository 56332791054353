<template>
  <div class="associated">
    <div class="associated_top">
      <div class="associated_img">
        <img :src="companyinfo.companyLogo" alt="" />
        <span style="padding-left:10px">{{ companyinfo.companyName }}</span>
      </div>
     <div style="display:flex;padding-top: 10px;">
        <div class="img_commers" style="padding: 0px 10px">
        <div class="color_img">
          <img src="../../../assets/image/jr-icon-poeple.png" alt="" />
        </div>
        <span class="name"> {{ secretaryGeneralName }}</span>
      </div>

      <div class="img_commer" style="padding: 0px 10px">
        <div class="color_img">
          <img src="../../../assets/image/jr-icon-commerce.png" alt="" />
        </div>
        <span class="name"> {{ chamberCommerceName }}</span>
      </div>
     </div>
    </div>
    <div class="switch_tab">
      <div class="label_management">
        <el-table
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="编号" align="center" type="index"></el-table-column>
          <el-table-column label="项目名称" align="center">
            <template v-slot="{ row }">
              <img :src="row.headImgUrl" width="42" height="42" alt="" />
            </template>
          </el-table-column>
          <el-table-column label="姓名" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.realName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="职务" prop="companyJob" align="center"></el-table-column>

          <el-table-column label="手机号" prop="phone" align="center"></el-table-column>

          <el-table-column label="创建时间" prop="createTime" align="center"></el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button style="color: #146aff" type="text" @click="userinfoClick(scope.row)"
                >详情
              </el-button>

              <!-- <span v-if="item.isDelete == 0" class="edit" @click="delrelevancy(item)">冻结</span>
              <span v-if="item.isDelete == 1" class="edit" @click="delrelevancy(item)">激活</span> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="new_page">
                  <el-pagination :current-page="queryInfo.pageNum" :page-sizes="[10, 30, 50]"
                      :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
                      @size-change="handleSizeChange" @current-change="handleCurrentChange" />
              </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getCorrelateUser, getcompany } from '@/api/datement'
export default {
  name: 'CorrelateUser',
  computed: {
    id() {
      return this.$route.query.id * 1
    },
    secretaryGeneralName(){
        return this.$route.query.secretaryGeneralName 
    },
    chamberCommerceName(){
        return this.$route.query.chamberCommerceName
    }
  },
  data() {
    return {
      queryInfo: {
        companyId: this.$route.query.id * 1
      },
      listData: [],
      total: 0,
      companyinfo: {}
    }
  },
  created() {
    this.getCorrelateUser()
    this.getcompany()
  },
  methods: {
    async getCorrelateUser() {
      const res = await getCorrelateUser(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data
      }
    },
    //查询企业信息回显
    async getcompany() {
      const res = await getcompany(this.id)
      if (res.data.resultCode == 200) {
        this.companyinfo = res.data.data
      }
    },
    userinfoClick(item) {
      this.$router.push({
        name: 'userinfo',
        query: { id: item.wechatId }
      })
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el_upload .el-form-item {
  margin-bottom: 0px;
}
::v-deep .name_form .el-input__suffix {
  position: absolute;
  height: 100%;
  right: -54px;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}
::v-deep .el-step__title {
  font-size: 14px;
  line-height: 38px;
}

::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 556px;
}
::v-deep .name_form .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 496px;
}

.associated {
  background: #ffffff;
  .img_commer {
    display: flex;
    border: 1px solid #146aff;
    border-radius: 39px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #146aff;
    }
    .color_img {
      background: #146aff;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .img_commers {
    display: flex;
    border: 1px solid #ff9c00;
    border-radius: 39px;
    margin: 0px 10px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #ff9c00;
    }
    .color_img {
      background: #ff9c00;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .associated_top {
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 0px 0px 0px;
    .associated_img {
      display: flex;
      align-items: center;
      img {
        width: 42px;
        height: 42px;
        border-radius: 5px;
      }
    }
  }
  .switch_tab {
    background: #ffffff;
    margin-top: 20px;

    .essential_information {
      padding: 30px 40px;
      .general_input {
        width: 845px;
        border: 1px solid #d4d4d4;
        padding: 14px 16px;

        .input_flex {
          display: flex;
          justify-content: space-between;
          padding: 7px 0px;
          span {
            padding-left: 15px;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
      .img_tips {
        position: relative;
        top: -10px;
        color: #6ba1ff;
      }

      h4 {
        color: #146aff;
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .label_management {
    padding: 10px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: left;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
}
</style>
